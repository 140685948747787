export const provincesMap = new Map([
    ['CEN', '4'],
    ['EAS', '8'],
    ['NOR', '6'],
    ['NOC', '7'],
    ['NOW', '3'],
    ['SAB', '5'],
    ['SOU', '2'],
    ['UVA', '9'],
    ['WES', '1'],
]);

export const districtsMap = new Map([
    ['CEN001', '7'],
    ['CEN002', '10'],
    ['CEN003', '11'],
    ['EAS001', '12'],
    ['EAS002', '13'],
    ['EAS003', '14'],
    ['NOR001', '17'],
    ['NOR002', '18'],
    ['NOR003', '19'],
    ['NOR004', '20'],
    ['NOR005', '21'],
    ['NOC001', '15'],
    ['NOC002', '16'],
    ['NOW001', '6'],
    ['NOW002', '8'],
    ['SAB002', '22'],
    ['SAB001', '9'],
    ['SOU003', '23'],
    ['SOU002', '5'],
    ['SOU001', '4'],
    ['UVA001', '24'],
    ['UVA002', '25'],
    ['WES001', '1'],
    ['WES002', '2'],
    ['WES003', '3'],
]);

export const citiesMap = new Map([
    ['Akurana', 10],
    ['Aladeniya', 13],
    ['Alawatugoda', 15],
    ['Ankubura', 33],
    ['Bokkawala', 92],
    ['Delpitiya', 153],
    ['Deltota', 154],
    ['Galagedara', 192],
    ['Galaha', 194],
    ['Gampola', 209],
    ['Gurudeniya - Gelioya', 229],
    ['Hasalaka', 239],
    ['Hataraliyadda', 240],
    ['Hunnasgiriya', 265],
    ['Kadugannawa', 286],
    ['Kandy', 327],
    ['Karaliyedde', 333],
    ['Katugasthota', 342],
    ['Kengalla', 360],
    ['Kundasale', 409],
    ['Madawala', 423],
    ['Mailapitiya', 441],
    ['Menikhinna', 493],
    ['Nawalapitiya', 552],
    ['Nugawela', 587],
    ['Panwila', 622],
    ['Peradeniya', 634],
    ['Pilimathalawa', 641],
    ['Pujapitiya', 668],
    ['Pussellawa', 678],
    ['Rajawella', 688],
    ['Rikillagaskada', 703],
    ['Talatuoya', 735],
    ['Teldeniya', 744],
    ['Tennekumbura', 746],
    ['Thalakiriyagama', 750],
    ['Udatenna - Medamaha Nuwara', 784],
    ['Ududumbara', 786],
    ['Wattegama', 837],
    ['Weligalla', 846],
    ['Werellagama', 868],
    ['Ambathanna', 881],
    ['Dambulla', 143],
    ['Devahuwa', 159],
    ['Galewela', 197],
    ['Inamaluwa', 273],
    ['Matale', 467],
    ['Naula', 548],
    ['Neelagama', 558],
    ['Palapathwala', 603],
    ['Pallepola', 606],
    ['Rattota', 700],
    ['Ukuwela', 793],
    ['Wilgamuwa', 870],
    ['Yatawatta', 878],
    ['Ginigathena', 215],
    ['Hanguranketa', 236],
    ['Hatton', 241],
    ['Kandapola', 325],
    ['Keertibandarapura', 353],
    ['Kotagala', 394],
    ['Maskeliya', 465],
    ['Nanu Oya', 541],
    ['Nortonbridge', 584],
    ['Norwood', 585],
    ['Nuwara Eliya', 590],
    ['Padiyapelella', 598],
    ['Pundaluoya', 674],
    ['Ragala', 684],
    ['Talawakele', 737],
    ['Tawalantenna', 743],
    ['Udupussellawa', 789],
    ['Wattawala', 836],
    ['Arasaditivu - Kokkaddicholai', 40],
    ['Kokkaddichcholai', 381],
    ['Arayampathy', 41],
    ['Batticaloa', 74],
    ['Chenkaladi', 110],
    ['Eravur', 189],
    ['Kalawanchikudy', 302],
    ['Kaluwankarni', 313],
    ['Kattankudy', 340],
    ['Kirankulam', 370],
    ['Kurukkalmadam', 411],
    ['Main Street - Valachchenai', 442],
    ['Valachchenai - Oddamawadi', 804],
    ['Valachchenai', 803],
    ['Mandoor - Palamunai', 453],
    ['Periyakallar', 636],
    ['Periyaporathvu', 637],
    ['Rideetenna', 701],
    ['Thalankuda', 752],
    ['Thiavaddavan - Oddamavadi', 759],
    ['Vakarai', 802],
    ['Vellavely', 812],
    ['Addalachchenai', 1],
    ['Akkaraipattu', 6],
    ['Alayadivembu', 17],
    ['Ampara', 28],
    ['Chawalakadai', 108],
    ['Dehiatthakandiya', 147],
    ['Deiattakandiya', 150],
    ['Hingurana', 252],
    ['Irakkamam', 276],
    ['Kalmunai', 303],
    ['Kalmunaikudy - 14', 305],
    ['Karaitivu - Ep', 332],
    ['Maha Oya', 429],
    ['Nintavur', 578],
    ['Oluvil', 592],
    ['Padiyatalawa', 599],
    ['Pottuvil', 662],
    ['Sainthamaruthu', 705],
    ['Samanthurai', 708],
    ['Sandunpura', 711],
    ['Tirukkovil', 774],
    ['Uhana', 792],
    ['Gantalawa', 214],
    ['Gomarankadawela', 225],
    ['Kantale', 330],
    ['Kinniya', 367],
    ['Kuchchiveli', 402],
    ['Morawewa', 512],
    ['Mutur', 527],
    ['Nilaveli', 573],
    ['Nilaveli - Irrakkandy', 574],
    ['Pulmoddai', 671],
    ['Serunuwara', 715],
    ['Sinna Kinniya', 719],
    ['Siripura', 722],
    ['Trincomalee', 780],
    ['Alaveddy', 14],
    ['Atchuvely', 45],
    ['Chankanai', 106],
    ['Chavakachcheri', 107],
    ['Chunnakam', 114],
    ['Jaffna', 281],
    ['Kaithady', 293],
    ['Karainagar', 331],
    ['Kayts', 351],
    ['Kodikamam', 379],
    ['Kokuvil', 383],
    ['Kopay', 385],
    ['Kuncharkadai', 408],
    ['Mallakam', 447],
    ['Manipay', 454],
    ['Manthikai', 457],
    ['Maruthanarmadam', 464],
    ['Mathagal', 471],
    ['Myliddy', 528],
    ['Nallur', 536],
    ['Nelliady', 566],
    ['Pandatharippu', 614],
    ['Puloly', 672],
    ['Punkudutivu', 675],
    ['Punnalaikaduvan', 676],
    ['Puttur', 681],
    ['Sandilipay', 710],
    ['Siruppiddy', 725],
    ['Talayadi', 738],
    ['Tellippalai', 745],
    ['Thirunelveli', 765],
    ['Uduvil', 790],
    ['Urumpirai', 800],
    ['Valvettithurai', 805],
    ['Velanai', 811],
    ['Mannar', 456],
    ['Murungan', 524],
    ['Nanattan', 539],
    ['Silawattura', 718],
    ['Siritoppu', 724],
    ['Thalaimannar Pier', 734],
    ['Thalaimannar', 749],
    ['Tharapuram', 757],
    ['Mallavi', 448],
    ['Thunnukai - Mallavi', 772],
    ['Mankulam', 455],
    ['Mulattivu', 518],
    ['Mulliyawalai', 521],
    ['Nayaru', 554],
    ['Oddusuddan', 591],
    ['Puthukudiyiruppu', 679],
    ['Visvamadhukulam', 818],
    ['Cheddikulam', 109],
    ['Kanagarayamkulam', 318],
    ['Nedunkarny', 557],
    ['Omanthai', 593],
    ['Vavuniya', 809],
    ['Akkarayankulam', 9],
    ['Iyakachchi', 278],
    ['Kilinochchi', 365],
    ['Mulankavil', 517],
    ['Pallai', 604],
    ['Paranthan', 625],
    ['Vadiyadi', 801],
    ['Anuradhapura', 34],
    ['Bogaswewa - Ikirigollewa', 91],
    ['Bulnewa', 103],
    ['Eppawela', 188],
    ['Galenbindunuwewa', 196],
    ['Galkulama', 200],
    ['Galnewa', 206],
    ['Habarana', 231],
    ['Horowupotana', 263],
    ['Ihalawewa - Kiralogama', 269],
    ['Kahatagasdigiliya', 291],
    ['Kebitigollawa', 352],
    ['Kekirawa', 356],
    ['Korasagalla', 387],
    ['Maha Vilachchiya', 430],
    ['Maradankadawala', 460],
    ['Medawachchiya', 483],
    ['Mihintale', 495],
    ['Nochchiyagama', 582],
    ['Oyamaduwa', 596],
    ['Padaviya', 597],
    ['Pothanegama', 661],
    ['Pubbogama', 666],
    ['Rajanganaya', 687],
    ['Rambewa', 690],
    ['Sippukulama', 721],
    ['Talawa', 736],
    ['Thambuthegama', 756],
    ['Aralaganwila', 37],
    ['Bakamuna', 58],
    ['Damanayaya', 140],
    ['Diyasenapura', 169],
    ['Elahera', 184],
    ['Giritale', 218],
    ['Hingurakdamana', 250],
    ['Hingurakgoda', 251],
    ['Kaduruwela', 287],
    ['Kaudulla', 350],
    ['Manampitiya', 452],
    ['Medirigiriya', 486],
    ['Minneriya', 497],
    ['Palugasdamana', 609],
    ['Pansal Godella', 621],
    ['Polonnaruwa', 655],
    ['Pulasthigama', 669],
    ['Sewanapitiya', 716],
    ['Siripura - Dehiattakandiya', 723],
    ['Welikanda', 850],
    ['Alawwa', 16],
    ['Ambanpola', 25],
    ['Bamunawala', 62],
    ['Bandarakoswatte', 64],
    ['Barampola Junction', 67],
    ['Battulu Oya', 77],
    ['Bingiriya', 89],
    ['Dambadeniya', 141],
    ['Dodangaslanda', 174],
    ['Dummalasuriya', 178],
    ['Galapitamulla', 195],
    ['Galgamuwa', 198],
    ['Giriulla', 219],
    ['Gokarella', 224],
    ['Gonagama', 226],
    ['Hettipola', 245],
    ['Hiripitiya', 255],
    ['Horambawa', 260],
    ['Ibbagamuwa', 266],
    ['Kadahapola', 284],
    ['Kalugamuwa Junction - Kalugamuwa', 310],
    ['Kamburupola', 317],
    ['Kandanagedera', 324],
    ['Katumuluwa', 344],
    ['Katupotha', 347],
    ['Keppetiwalana', 362],
    ['Ketawalagedara - Wewagama', 364],
    ['Kobeigane', 377],
    ['Kuliyapitiya', 404],
    ['Kumbukgate', 406],
    ['Kurunegala', 412],
    ['Maho', 440],
    ['Makadura', 443],
    ['Maspotha', 466],
    ['Mavee Ela Junction', 476],
    ['Mawathagama', 480],
    ['Meegalawa', 488],
    ['Melsiripura', 492],
    ['Minuwangate', 498],
    ['Mudukatuwa', 515],
    ['Nagollagama', 530],
    ['Nagollagoda', 531],
    ['Nankawaththa', 540],
    ['Narammala', 543],
    ['Narangoda', 544],
    ['Nikaweratiya', 572],
    ['Pannala', 617],
    ['Pilessa', 639],
    ['Polgahawela', 653],
    ['Polpithigama', 657],
    ['Potuhera', 665],
    ['Ridigama', 702],
    ['Saliya Ashokapura', 706],
    ['Sandalankawa', 709],
    ['Thuththiripitigama - Kadawalagedara', 773],
    ['Udubaddawa', 785],
    ['Vilaththawa - Puliyamkadawala', 817],
    ['Wariyapola', 833],
    ['We - Uda', 839],
    ['Weerapokuna', 843],
    ['Wellawa', 863],
    ['Welpothuwewa - Boruluwewa', 865],
    ['Wennoruwa', 867],
    ['Withikuliya Junction', 872],
    ['Yakwila - Paragammana', 876],
    ['Ilippugamuwa', 883],
    ['Kurunegala Rd - Bopitiya', 886],
    ['Anamaduwa', 29],
    ['Arachchikattuwa', 35],
    ['Arndigama', 42],
    ['Bangadeniya', 66],
    ['Botalegama', 99],
    ['Chilaw', 112],
    ['Dankotuwa', 145],
    ['Etalai', 190],
    ['Inigodawela', 275],
    ['Kakkapalliya', 294],
    ['Kalpitiya', 306],
    ['Kalpitiya Road - Mampuriya', 307],
    ['Mampuriya', 451],
    ['Kandakudah', 320],
    ['Kandathoduwawa', 326],
    ['Katuneriya', 346],
    ['Kokkawila', 382],
    ['Lunuwila', 418],
    ['Madampe', 420],
    ['Madurankuliya', 426],
    ['Mahawewa', 435],
    ['Mahawewa - Thoduwawa', 436],
    ['Marawila', 462],
    ['Medawakkulama - Andigama', 484],
    ['Mundelama', 523],
    ['Nalladarankattuwa', 535],
    ['Nattandiya', 546],
    ['Nattandiya - Koswatta', 547],
    ['Nawagaththegama', 550],
    ['Noraicholai', 583],
    ['Panirendawa', 616],
    ['Puliyankulama Junction', 670],
    ['Puttalam', 680],
    ['Saliyawewa', 707],
    ['Serakuliya', 714],
    ['Tabbowa', 733],
    ['Thambarawila', 755],
    ['Toppuwa Junction', 778],
    ['Vanatavilluwa', 807],
    ['Waikkal', 822],
    ['Welipennagahamulla', 854],
    ['Wellamankara', 861],
    ['Wennappuwa', 866],
    ['Amithirigala', 27],
    ['Aranayake', 39],
    ['Bulathkohupitiya', 101],
    ['Dehiowita', 148],
    ['Deraniyagala', 158],
    ['Galigamuwa', 199],
    ['Hemmathagama', 244],
    ['Imbulgasdeniya', 272],
    ['Kegalle', 354],
    ['Kithulgala', 376],
    ['Mattamagoda - Kotiyakumbura', 473],
    ['Mawanella', 477],
    ['Nelundeniya', 569],
    ['Rambukkana', 691],
    ['Ruwanwella', 704],
    ['Thulhiriya', 769],
    ['Waharaka', 821],
    ['Warakapola', 830],
    ['Weligamuwa - Rambukkana', 849],
    ['Yatiyantota', 880],
    ['Atakalampanna', 43],
    ['Balangoda', 59],
    ['Divurmpitiya - Gatahatta', 168],
    ['Eheliyagoda', 182],
    ['Embilipitiya', 186],
    ['Ganegama - Pelmadulla', 211],
    ['Godakawela', 223],
    ['Hidellana - Rathnapura', 247],
    ['Kahawatta', 292],
    ['Kalawana', 300],
    ['Kaltota', 308],
    ['Kiriella', 372],
    ['Kolonna', 384],
    ['Kuruwita', 413],
    ['Nivitigala', 581],
    ['Pallebedda', 605],
    ['Pambahinna', 610],
    ['Parakaduwa', 623],
    ['Pelmadulla', 633],
    ['Rakwana', 689],
    ['Ratnapura', 699],
    ['Suriyakanda', 731],
    ['Thimbolketiya', 763],
    ['Ambalanthota', 24],
    ['Angunukolapelessa', 31],
    ['Beliatta', 80],
    ['Bolana', 94],
    ['Debarawewa', 146],
    ['Hambantota', 235],
    ['Hungama', 264],
    ['Kalametiya', 299],
    ['Kirama', 368],
    ['Kirinda', 374],
    ['Lunugamvehera', 417],
    ['Mahawela - Nakulugama', 434],
    ['Middeniya', 494],
    ['Nilwella', 576],
    ['Ranna', 693],
    ['Sooriyawewa', 728],
    ['Suduwelipelessa Kirinda', 729],
    ['Suriyawewa', 732],
    ['Tangalle', 741],
    ['Thissamaharamaya', 766],
    ['Tissamaharama', 775],
    ['Walasmulla', 824],
    ['Walasmulla Rd - Weeraketiya', 825],
    ['Weeraketiya', 842],
    ['Weerawila', 844],
    ['Akuressa', 11],
    ['Bengamuwa', 85],
    ['Deiyandara', 151],
    ['Deniyaya', 157],
    ['Devinuwara', 161],
    ['Dickwella', 163],
    ['Dodanduwa', 173],
    ['Gandara', 210],
    ['Hakmana', 232],
    ['Kakunadura', 295],
    ['Kamburugamuwa', 315],
    ['Kamburupitiya', 316],
    ['Kottegoda', 400],
    ['Kudawella', 403],
    ['Matara', 468],
    ['Mawarala', 478],
    ['Meddawatta', 485],
    ['Mirissa', 502],
    ['Morawaka', 511],
    ['Pallimulla', 608],
    ['Pitabeddara', 644],
    ['Puranawella', 677],
    ['Ransegoda  Hakmana', 695],
    ['Thihagoda', 761],
    ['Urubokka', 799],
    ['Walasgala', 823],
    ['Waralla', 831],
    ['Weligama', 847],
    ['Ahangama', 3],
    ['Ahungalla', 4],
    ['Aluthwala', 20],
    ['Ambalangoda', 23],
    ['Baddegama', 52],
    ['Balapitiya', 60],
    ['Batapola', 70],
    ['Boossa', 95],
    ['Denipitiya', 156],
    ['Devata Junction', 160],
    ['Elpitiya', 185],
    ['Galle', 201],
    ['Gonapinuwala', 227],
    ['Habaraduwa', 230],
    ['Hikkaduwa', 248],
    ['Hipankanda - Uragasmanhandiya', 253],
    ['Imaduwa', 271],
    ['Kalaigana', 298],
    ['Kaluwella', 314],
    ['Karandeniya', 334],
    ['Katuwana', 348],
    ['Kotapola', 395],
    ['Magalle', 427],
    ['Meetiyagoda', 491],
    ['Nakiyadeniya', 532],
    ['Neluwa', 570],
    ['Pinnaduwa - Akmeemana', 643],
    ['Pitigala', 645],
    ['Poddala', 648],
    ['Ratgama', 697],
    ['Suduwella', 730],
    ['Talgaswela', 739],
    ['Tawalama', 742],
    ['Thalagaha - Akmeemana', 747],
    ['Thalgampala', 754],
    ['Udugama', 787],
    ['Uragasmanhandiya', 795],
    ['Urala', 796],
    ['Wanduramba', 829],
    ['Yakkalamulla', 875],
    ['Ambagasdowa', 22],
    ['Atampitiya', 44],
    ['Badulla', 54],
    ['Badulla Rd - Halpe', 55],
    ['Ballekatuwa', 61],
    ['Bandarawela', 65],
    ['Dambagalla', 142],
    ['Diyathalawa', 170],
    ['Girandurukotte', 216],
    ['Haldummulla', 233],
    ['Hali Ela', 234],
    ['Haputale', 238],
    ['Kahaniyagoda', 289],
    ['Kandaketiya - Loggaloya Junction', 319],
    ['Keppetipola', 361],
    ['Lunugala', 416],
    ['Madulsima', 425],
    ['Mahiyanganaya', 438],
    ['Mahiyankanaya', 439],
    ['Medagama', 481],
    ['Meegahakiwla', 487],
    ['Namunukula', 538],
    ['Passara', 626],
    ['Thalangamuwa', 751],
    ['Welimada', 851],
    ['Andaulpotha', 884],
    ['Arambekema - Hambegamuwa', 38],
    ['Badalkumbura', 51],
    ['Bibile', 88],
    ['Buttala', 104],
    ['Danduma', 144],
    ['Dobagahawela', 172],
    ['Kataragama', 338],
    ['Koslanda', 391],
    ['Kumbukkana', 407],
    ['Monaragala', 506],
    ['Siyambalanduwa', 726],
    ['Tanamalwila', 740],
    ['Thelulla', 758],
    ['Weliyaya', 859],
    ['Wellawaya', 864],
    ['Ambathale', 26],
    ['Athurugiriya', 46],
    ['Avissawella', 49],
    ['Battaramulla', 72],
    ['Bokundara', 93],
    ['Boralesgamuwa', 98],
    ['Colombo', 115],
    ['Colombo - 02', 116],
    ['Colombo - 03', 117],
    ['Colombo - 04', 118],
    ['Colombo - 05', 119],
    ['Colombo - 06', 120],
    ['Colombo - 07', 121],
    ['Colombo - 08', 122],
    ['Colombo - 09', 123],
    ['Colombo - 10', 124],
    ['Colombo - 11', 125],
    ['Colombo - 13', 126],
    ['Colombo - 14', 127],
    ['Colombo - 15', 128],
    ['Dehiwala', 149],
    ['Ethul Kotte', 191],
    ['Gamgodawila', 207],
    ['Godagama', 220],
    ['Gothatuwa', 228],
    ['Hanwella', 237],
    ['Hokandara North', 256],
    ['Hokandara South', 257],
    ['Homagama', 258],
    ['Ihala Kosgama', 268],
    ['Kaduwela', 288],
    ['Kalubowila', 309],
    ['Kesbewa', 363],
    ['Kohuwela', 380],
    ['Kirulapone', 388],
    ['Kosgama', 389],
    ['Koswatta', 392],
    ['Kotikawatte', 397],
    ['Kottawa', 398],
    ['Kotte', 399],
    ['Madapatha', 422],
    ['Madiwela', 424],
    ['Maharagama', 433],
    ['Malabe', 446],
    ['Mattegoda', 474],
    ['Meegoda', 489],
    ['Mirihana', 501],
    ['Moratuwa', 508],
    ['Mount Lavinia', 514],
    ['Mulleriyawa - New Town', 519],
    ['Mulleriyawa North', 520],
    ['Narahenpita', 542],
    ['Nawala', 551],
    ['Nawinna - High Level Road', 553],
    ['Nugegoda', 588],
    ['Orugodawatta', 595],
    ['Padukka', 600],
    ['Padukka - Meepe Junction', 601],
    ['Pannipitiya', 619],
    ['Pelawetta', 630],
    ['Piliyandala', 642],
    ['Polgasowita', 654],
    ['Rajagiriya', 686],
    ['Ranala', 692],
    ['Ratmalana', 698],
    ['Thalahena', 748],
    ['Thalawathugoda', 753],
    ['Walgama Junction - Athrugiriya', 826],
    ['Wellampitiya', 862],
    ['Akarawita', 5],
    ['Attangalla', 48],
    ['Badalgama', 50],
    ['Baduragoda', 56],
    ['Bataleeya', 69],
    ['Batepola - Dunagaha', 71],
    ['Batuwatta', 78],
    ['Bemmulla', 84],
    ['Biyagama', 90],
    ['Dalupotha', 138],
    ['Delgoda', 152],
    ['Demanhandiya - Miriswatta', 155],
    ['Dewalapola', 162],
    ['Dikowita', 164],
    ['Divulapitiya', 166],
    ['Dompe', 176],
    ['Dunagaha', 179],
    ['Ekala', 183],
    ['Gampaha', 208],
    ['Ganemulla', 212],
    ['Giridara', 217],
    ['Hedala', 242],
    ['Heiyanthuduwa', 243],
    ['Idigolla', 267],
    ['Jaela', 279],
    ['Kadawatha', 285],
    ['Kal - Eliya', 296],
    ['Kalagedihena', 297],
    ['Kandana', 322],
    ['Katana', 336],
    ['Kattuwa', 341],
    ['Katunayake', 345],
    ['Katuwellagama Junction', 349],
    ['Kelaniya', 357],
    ['Kimbulapitiya', 366],
    ['Kiribathgoda', 371],
    ['Kirillawela - Imbulgoda', 373],
    ['Kirindiwela', 375],
    ['Kochchikade', 378],
    ['Kotadeniyawa', 393],
    ['Kotugoda', 401],
    ['Kurana - Katunayaka', 410],
    ['Loluwagoda', 415],
    ['Mabima', 419],
    ['Madampella', 421],
    ['Mahabage', 431],
    ['Mahara', 432],
    ['Makola', 444],
    ['Malwatu - Hiripitiya', 450],
    ['Marandagahamula', 461],
    ['Mawaramandiya', 479],
    ['Millathe - Kiridiwela', 496],
    ['Minuwangoda', 499],
    ['Mirigama', 500],
    ['Mudungoda', 516],
    ['Nagoda', 529],
    ['Nalla', 534],
    ['Naranwala', 545],
    ['Negombo', 559],
    ['Nelligahamulla - Atthanagalla', 567],
    ['Nittambuwa', 579],
    ['Pallewela', 607],
    ['Pamunugama', 611],
    ['Pasyala', 627],
    ['Peliyagoda', 631],
    ['Pitipana', 646],
    ['Pitipana - South', 647],
    ['Pohonnaruwa - Meerigama', 649],
    ['Pugoda', 667],
    ['Puwakwetiya - Kadawata', 682],
    ['Raddolugama', 683],
    ['Ragama', 685],
    ['Ranpokunugama', 694],
    ['Seeduwa', 713],
    ['Siyambalape', 727],
    ['Thibirigaskatuwa', 760],
    ['Thihariya', 762],
    ['Udugampola', 788],
    ['Urapola', 798],
    ['Veyangoda', 814],
    ['Wattala', 834],
    ['Webada', 840],
    ['Welisara - Kandana', 856],
    ['Welisara - Maththumagala', 857],
    ['Weliweriya', 858],
    ['Weweldeniya', 869],
    ['Wilimbula - Henegama', 871],
    ['Yakkala', 874],
    ['Agalawatta', 2],
    ['Aluthgama', 19],
    ['Anguruwathota', 32],
    ['Arakawila - Handapangoda', 36],
    ['Badureliya', 57],
    ['Bandaragama', 63],
    ['Batagoda', 68],
    ['Bellapitiya - Anguruwathota', 83],
    ['Beruwela - Gorakadoowa', 86],
    ['Beruwala', 87],
    ['Bulathsinhala', 102],
    ['Dodangoda', 175],
    ['Horana', 261],
    ['Ingiriya', 274],
    ['Ittapana', 277],
    ['Kalutara North', 311],
    ['Kalutara South', 312],
    ['Katukurunda', 343],
    ['Kumbuka West', 405],
    ['Maggona', 428],
    ['Matugama', 475],
    ['Molligoda', 504],
    ['Moragahahena', 507],
    ['Morontuduwa', 513],
    ['Neboda', 556],
    ['Panadura', 612],
    ['Panapitiya - Kaluthara', 613],
    ['Payagala', 628],
    ['Pelawatte', 629],
    ['Pokunuwita', 651],
    ['Polegoda - Mahagama', 652],
    ['Poruwadanda', 660],
    ['Wadduwa', 819],
    ['Wekada - panadura', 845],
    ['Welipenna', 853],
    ['Welipenne - Site B', 855],
    ['Yatadola - Mathugama', 877],
]);
